<template>
	<h1>Form Items Preview</h1>
	<!-- prettier-ignore -->
	<form class="structured-form">
		<FormText placeholder="Something" />
		<FormDropdown v-model="myChoice1" :options="ddOptions" />
		<FormText :readOnly="true" :readOnlyValue="'I am read-only'" />
		<FormText :locked="true" :readOnlyValue="'I am locked'" />
		<FormText label="Label" placeholder="Something" />
		<FormDropdown label="Label" v-model="myChoice2" :options="ddOptions" />
		<FormDimensions />
		<div>
			<FormButton :level="1" /><FormButton :level="2" value="cancel" />
		</div>
	</form>
	<form class="structured-form">
		<div class="suggestions-wrap">
			<FormSuggestions :items="suggestions" />
		</div>
		<FormToggle v-model="toggle" label="Toggle me" />
		<FormToggle v-model="toggle" />
		<FormToggle v-model="toggle" tiny label="Toggle me" />
		<FormToggle v-model="toggle" tiny />
		<FormLabels :labels="theLabels" />
		<FormLabels :labels="theLabels" :isMedium="true" />
		<FormRadios v-model="vegetable1" name="preview1" :radioOptions="radioOptions" />
		<FormRadios v-model="vegetable2" name="preview2" :radioOptions="radioOptions" label="Vegetables" />
	</form>
</template>

<script>
import FormText from '@/components/FormText'
import FormDropdown from '@/components/FormDropdown'
import FormButton from '@/components/FormButton'
import FormLabels from '@/components/FormLabels'
import FormRadios from '@/components/FormRadios'
import FormSuggestions from '@/components/FormSuggestions'
import FormToggle from '@/components/FormToggle'
import FormDimensions from '@/components/FormDimensions'

import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsPreview',
	components: {
		FormText,
		FormDropdown,
		FormButton,
		FormLabels,
		FormRadios,
		FormSuggestions,
		FormToggle,
		FormDimensions,
	},
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Preview',
		})
	},
	data() {
		return {
			// v-models
			toggle: false,

			vegetable1: null,
			vegetable2: null,
			radioOptions: [
				{
					text: 'Cauliflower',
					value: 'cauliflower',
					checked: true,
				},
				{
					text: 'Asparagus',
					value: 'asparagus',
				},
				{
					text: 'Red Beets',
					value: 'red-beets',
				},
			],
			myChoice1: null,
			myChoice2: null,
			ddOptions: [
				{
					value: 'spaghetti-carbonara',
					display: 'Spaghetti Carbonara',
					selected: true, // <-- This sets default value
				},
				{
					value: 'ravioli',
					display: 'Ravioli',
				},
				{
					value: 'penne-alla-vodka',
					display: 'Penne alla Vodka',
				},
			],
			suggestions: [
				{
					display: 'One',
					value: 'one',
				},
				{
					display: 'Two',
					value: 'two',
				},
				{
					display: 'Three',
					value: 'three',
				},
				{
					display: 'Four',
					value: 'four',
				},
			],
			theLabels: [
				{
					value: 'green',
					display: 'green',
				},
				{
					value: 'fluffy',
					display: 'fluffy',
				},
			],
		}
	},
}
</script>

<style scoped lang="scss">
form {
	width: 2.5rem;
	float: left;
	margin-right: 0.4rem;
}
.suggestions-wrap {
	position: relative;
	width: 2.5rem;
	height: 1.65rem;
	margin-bottom: 0.3rem;
}
</style>
